<template>
  <div class="row">
    <div class="col-12 col-sm-12">
      <div class="row">
        <div class="col-12 col-sm-12">
          <h2 class="mb-25px">
            My Account
          </h2>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12 col-sm-12">
          <div
            class="card p-60px"
            :class="[height_card < 550 ? 'pb-450px' : '']"
            ref="card"
          >
            <div class="row" v-if="loading == true">
              <div class="col-12 col-sm-12">
                <grid-loader
                  class="expertel-loader"
                  color="#ff502d"
                  :loading="loading"
                  :size="20"
                  sizeUnit="px"
                ></grid-loader>
              </div>
            </div>
            <div class="row" v-if="loading == false">
              <div class="col-12 col-sm-12">
                <h2>Customer Information</h2>
              </div>
              <div class="col-12 col-sm-12 col-xl-6">
                <div class="mt-4 settings-form">
                  <div class="input-container">
                    <label for="">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="data_users.name"
                      disabled
                    />
                  </div>
                  <div class="input-container">
                    <label for="">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="data_users.email"
                      disabled
                    />
                  </div>
                  <div class="input-container" v-if="edit === false">
                    <label for="">Created</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="data_users.created"
                      disabled
                    />
                  </div>
                  <p
                    class="color-gray"
                    :style="{ 'font-size': '16px', padding: '0.75rem' }"
                    v-if="edit === true"
                  >
                    Created {{ data_users.created }}
                  </p>
                  <button
                    class="button-expertel float-right"
                    v-if="edit === false"
                    @click="editAccount()"
                  >
                    Edit information
                  </button>
                  <button
                    class="btn btn-outline-expertel rounded-0 float-right mr-md-3"
                    v-if="edit === false"
                  >
                    Change password
                  </button>
                  <button
                    class="button-expertel float-right"
                    v-if="edit === true"
                  >
                    Submit
                  </button>
                  <button
                    class="btn btn-outline-expertel rounded-0 float-right mr-3"
                    v-if="edit === true"
                    @click="editAccount()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["login_data", "id_user"],
  data() {
    return {
      height_card: 0,
      passwd: {
        current: "",
        new: "",
        re_new: ""
      },
      loading: false,
      edit: false,
      data_users: []
    };
  },
  created() {
    if (this.login_data !== undefined || this.login_data.success === false) {
      if (!this.login_data.success) {
        this.$router.push("/");
      } else {
        this.getUsers();
      }
    } else {
      this.$router.push("/");
    }
  },
  methods: {
    calculateHeight() {
      this.height_card = this.$refs.card.clientHeight;
    },
    getUsers() {
      const self = this;
      this.loading = true;
      this.axios({
        method: "get",
        url: `https://api.expertel.ca/api/v1/client/me/users`,
        responseType: "json",
        headers: {
          Authorization: "Bearer " + self.login_data.token
        }
      })
        .then(function(response) {
          const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ];

          self.data_users = response.data.filter(function(user) {
            return user.id == self.login_data.user.id;
          });

          const month = new Date(self.data_users[0].created).getMonth();
          const day = new Date(self.data_users[0].created).getDate();
          const year = new Date(self.data_users[0].created).getFullYear();
          self.data_users = {
            name: self.data_users[0].name,
            email: self.data_users[0].email,
            created: `${months[month]} ${day}, ${year}`
          };

          self.calculateHeight();
          self.loading = false;
        })
        .catch(error => this.makeToast("ERROR", error, "danger"));
    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(`${message}`, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
        variant: variant
      });
    },
    changeIDUser(id) {
      this.$emit("changeIDUser", id);
    },
    editAccount() {
      this.edit = !this.edit;
    }
  }
};
</script>
